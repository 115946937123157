import * as React from "react"
import { Link } from "gatsby"
import Logo from '../images/logo.svg';
import LogoWhite from '../images/logo_white.inline.svg';
import '../footer.css'

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="main-header-wrapper">
        <a href="https://ircllc.com">
          <img src={Logo} alt="Logo" />
        </a>
      </header>
      <main>
        {children}
      </main>
      <footer className="fr-footer">
        <div className="footer-content">
          <div className="footer-meta">
            <div className="section-content">
              <div className="footer-meta-content">
                <div className="footer-about">
                  <div className="made-by">
                    <img src={LogoWhite} alt="Logo" />
                  </div>
                  <div className="copyright">
                    {new Date().getFullYear()} © IRC, LLC
                  </div>
                </div>
                <div className="footer-links">
                  <div>
                    <h5>Company</h5>
                    <ul>
                      <li>
                        <a href="https://ircllc.com/about">About Us</a>
                      </li>
                      <li>
                        <a href="https://ircllc.com/services">Services</a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h5>Get in touch</h5>
                    <ul>
                      <li>
                        <Link to="/privacy-policy">contact@ircllc.com</Link>
                      </li>
                      <li>+1 212 571-3989</li>
                      <li>
                        PO Box 33, 69 Montgomery Street
                        Jersey City, NJ 07303
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
